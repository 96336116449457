class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        generic: _.get(record, 'generic'),
        name: _.get(record, 'name'),
        active: _.get(record, 'active'),
        applicable_load: _.get(record, 'applicable_load'),
        start_zone: _.get(record, 'start_zone'),
        priority: _.get(record, 'priority'),
        schedule: _.get(record, 'schedule'),
        rateparameter: _.get(record, 'pricing'),
        commercial_packages: _.map(_.get(record, 'commercial_packages'), 'commercial_package_id')
      }
    }
  }

  static getEmptyFormHandler(commercialpackage_id) {
    return function getEmptyFormValues() {
      return {
        generic: true,
        applicable_load: 'user',
        commercial_packages: _.compact([commercialpackage_id]),
        individual_pricing: false,
        two_way: false,
        active: true
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      return {
        name: _.get(values, 'name'),
        active: _.get(values, 'active'),
        applicable_load: _.get(values, 'applicable_load'),
        start_zone: _.get(values, 'start_zone'),
        priority: _.get(values, 'priority'),
        schedule: _.get(values, 'schedule'),
        generic: _.get(values, 'generic'),
        pricing: _.get(values, 'rateparameter.rateparameter_id'),
        commercial_packages: _.get(values, 'commercial_packages')
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name')
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
}

export default Callbacks
