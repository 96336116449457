import { FormatUtils, TimeUtils } from '@front/volcanion/utils'
import moment from 'moment'

class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      const now = moment()
      return {
        average_rating_start: 0,
        average_rating_end: 4,
        requestedAt_start: TimeUtils.formatDateFrontToBack(now.startOf('day'))
      }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      const { requestedAt_start, requestedAt_end, average_rating_start, average_rating_end, with_comment, ...rest } = values
      return _.merge({},
        rest,
        { rating: { rating: { '!': null } } },
        { requestedAt: FormatUtils.intervalFilter(requestedAt_start, requestedAt_end) },
        !!average_rating_start && { rating: { rating: { '>=': average_rating_start } } },
        !!average_rating_end && { rating: { rating: { '<=': average_rating_end } } },
        !!with_comment && { rating: { comment: { '!': null } } },
        _.has(rest, 'do.info.first_name') ? { do: { info: { first_name: { contains: rest?.do?.info?.first_name } } } } : {},
        _.has(rest, 'do.info.last_name') ? { do: { info: { last_name: { contains: rest?.do?.info?.last_name } } } } : {},
      )
    }
  }
}

export default Callbacks
