import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid, Typography, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, SingleOptionSelectorField, NumberField } from '@front/squirtle'

import { isRequired } from '@front/squirtle/utils/validators'

const EndAdornmentComponent = ({ labelKey = 'fiat.eur.sign' }) =>
  <Typography sx={{ fontSize: 'subtitle.fontSize', color: 'text.label' }}> {I18n.t(labelKey)} </Typography>

const Row1 = props =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={5}>
      <TextField
        name={'rateparameter.name'}
        label={I18n.t('name.label.one')}
        emptyValue='-'
        required
        validate={isRequired}
      />
    </Grid>
  </Grid>

const Row2 = ({ disable_name, enable_min_rate, enable_max_rate }) =>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs={12}>
      <Typography variant='h6' color='primary'>{'Configuration:'}</Typography>
    </Grid>
    <Grid item xs>
      <NumberField
        name={'rateparameter.flat_rate'}
        label={I18n.t('rateparameter.flat_rate')}
        type='number'
        minValue={0}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <EndAdornmentComponent />
        }}
        disabled={disable_name}
      />
    </Grid>
    <Grid item xs>
      <NumberField
        name={'rateparameter.km_rate'}
        label={I18n.t('rateparameter.km_rate')}
        type='number'
        minValue={0}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <EndAdornmentComponent />
        }}
        disabled={disable_name}
      />
    </Grid>
    <Grid item xs>
      <NumberField
        name={'rateparameter.minute_rate'}
        label={I18n.t('rateparameter.minute_rate')}
        type='number'
        minValue={0}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <EndAdornmentComponent />
        }}
        disabled={disable_name}
      />
    </Grid>
    <Grid item xs>
      <NumberField
        name={'rateparameter.min_rate'}
        label={I18n.t('rateparameter.min_rate')}
        enableCheckbox
        type='number'
        minValue={0}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <EndAdornmentComponent />
        }}
        disabled={disable_name || !enable_min_rate}
      />
    </Grid>
    <Grid item xs>
      <NumberField
        name={'rateparameter.max_rate'}
        label={I18n.t('rateparameter.max_rate')}
        enableCheckbox
        type='number'
        minValue={0}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <EndAdornmentComponent />
        }}
        disabled={disable_name || !enable_max_rate}
      />
    </Grid>
  </Grid>

const Row3 = ({ disable_name }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <NumberField
        name={'rateparameter.handling_rate'}
        label={I18n.t('rateparameter.handling_rate')}
        type='number'
        minValue={0}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <EndAdornmentComponent />
        }}
        disabled={disable_name}
      />
    </Grid>
    <Grid item xs>
      <NumberField
        name={'rateparameter.handling_slice'}
        label={I18n.t('rateparameter.handling_slice')}
        type='number'
        minValue={0}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <EndAdornmentComponent labelKey='minute.label.short' />
        }}
        disabled={disable_name}
      />
    </Grid>
    <Grid item xs>
      <NumberField
        name={'rateparameter.immediate_empty_flat_rate'}
        label={I18n.t('rateparameter.immediate_empty_flat_rate')}
        type='number'
        minValue={0}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <EndAdornmentComponent />
        }}
        disabled={disable_name}
      />
    </Grid>
    <Grid item xs>
      <NumberField
        name={'rateparameter.planned_empty_flat_rate'}
        label={I18n.t('rateparameter.planned_empty_flat_rate')}
        type='number'
        minValue={0}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <EndAdornmentComponent />
        }}
        disabled={disable_name}
      />
    </Grid>
    <Grid item xs>
      <NumberField
        name={'rateparameter.empty_km_rate'}
        label={I18n.t('rateparameter.empty_km_rate')}
        type='number'
        minValue={0}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <EndAdornmentComponent labelKey={'fiat.eur.convertion.km'} />
        }}
        disabled={disable_name}
      />
    </Grid>
  </Grid >

const Row4 = ({ disable_name }) =>
  <Grid container item xs={12} rowSpacing={2}>
    <Grid item xs={12}>
      <Typography variant='h6' color='primary'>{_.join([I18n.t('application.driver'), I18n.t('quotation.screen')], ', ')}</Typography>
    </Grid>
    <Grid item container xs={6}>
      <Grid item xs={12}>
        <Typography variant='subtitle' color='primary'>{I18n.t('action.label', { count: 2 })}:</Typography>
      </Grid>
      <Grid item xs>
        <Box display='flex' justifyContent='space-evenly'>
          <SingleOptionSelectorField
            name={'rateparameter.ridemodeconfig.validate_base'}
            optionLabel={_.join([I18n.t('action.verify'), _.toLower(I18n.t('price.label'))], ' ')}
            getOptionLabelProps={() => ({
              disableTypography: false,
              componentsProps: { typography: { fontSize: 13 } }
            })}
            clearOnEmpty={false}
            disabled={disable_name}
          />
          <SingleOptionSelectorField
            name={'rateparameter.ridemodeconfig.prefill_base'}
            optionLabel={_.join([I18n.t('action.prefill'), _.toLower(I18n.t('price.label'))], ' ')}
            getOptionLabelProps={() => ({
              disableTypography: false,
              componentsProps: { typography: { fontSize: 13 } }
            })}
            clearOnEmpty={false}
            disabled={disable_name}
          />
        </Box>
      </Grid>
    </Grid>
    <Grid item container xs={6}>
      <Grid item xs={13}>
        <Typography variant='subtitle' color='primary'>{I18n.t('display.label.alter')}:</Typography>
      </Grid>
      <Grid item xs>
        <Box display='flex' justifyContent='space-evenly'>
          <SingleOptionSelectorField
            name={'rateparameter.ridemodeconfig.display_handling_field'}
            optionLabel={I18n.t('quotation.wait_paid')}
            getOptionLabelProps={() => ({
              disableTypography: false,
              componentsProps: { typography: { fontSize: 13 } }
            })}
            clearOnEmpty={false}
            disabled={disable_name}
          />
          <SingleOptionSelectorField
            name={'rateparameter.ridemodeconfig.display_prepaid_field'}
            optionLabel={I18n.t('quotation.prepaid')}
            getOptionLabelProps={() => ({
              disableTypography: false,
              componentsProps: { typography: { fontSize: 13 } }
            })}
            clearOnEmpty={false}
            disabled={disable_name}
          />
          <SingleOptionSelectorField
            name={'rateparameter.ridemodeconfig.display_fee_field'}
            optionLabel={I18n.t('quotation.fees')}
            getOptionLabelProps={() => ({
              disableTypography: false,
              componentsProps: { typography: { fontSize: 13 } }
            })}
            clearOnEmpty={false}
            disabled={disable_name}
          />
        </Box>
      </Grid>
    </Grid>
  </Grid>

const PrimarySection = ({
  stepLabelKey = 'info.general',
  disable_name,
  ...rest
}) => {
  return (
    !!disable_name ? <>
      <Row2  {...rest} />
      <Row3 {...rest} />
      <Row4 {...rest} />
    </> :
      <>
        <StepLabel>
          <Typography variant='h5'> {I18n.t(stepLabelKey)} </Typography>
        </StepLabel>
        <StepContent>
          <Box sx={{ p: 2, boxShadow: 6, borderRadius: 3 }}>
            <Grid container rowSpacing={2}>
              <Row1 {...rest} />
              <Row2  {...rest} />
              <Row3  {...rest} />
              <Row4 {...rest} />
            </Grid>
          </Box>
        </StepContent>
      </>)
}

export default React.memo(PrimarySection)
