import React from 'react'
import { I18n } from '@front/volcanion'
import moment from 'moment'

import { TableCell, Chip } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row }) => {
  const margin = _.get(row, 'margin')
  const formattedTime = moment.utc(margin * 60 * 1000).format(margin > 60 ? "HH[h]mm[min]" : 'mm[min]')

  return <>
    <TableCell size={'small'}>
      <Chip label={I18n.t(!!row?.type ? `traffic_alert.type.${row?.type}` : 'none')} color={row?.type || 'primary'} variant={'outlined'} />
    </TableCell>
    <TableCell size={'small'}><TextCell value={_.get(row, 'name')} /></TableCell>
    <TableCell size={'small'}><TextCell value={formattedTime} /></TableCell>
    <TableCell size={'small'}>
      <TextCell hidden={!_.get(row, 'active')} value={I18n.t('status.active')} color='green' fontWeight='bold' />
      <TextCell hidden={!!_.get(row, 'active')} value={I18n.t('status.inactive')} color='red' fontWeight='bold' />
    </TableCell>
  </>
}

export default React.memo(Row)
