import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box } from '@mui/material'
import { ModelAutocompleteField } from '@front/squirtle'

const Title = props => <Grid item xs={12}> <Typography variant={'h5'}> {I18n.t('vehicletype.search')} </Typography></Grid>

const Row1 = props =>
  <Grid container item xs={10} spacing={1}>
    <Grid item xs={4}>
      <ModelAutocompleteField
        name='vehicletype_id'
        label={I18n.t('name.label', { count: 1 })}
        model_name={'vehicletype'}
        searchMode='search'
        autocompleteMutator={q => ({
          name_trkey: { fr: { contains: q } }
        })}
        labelKeys={['name_trkey.fr']}
        config={{
          populate: ['name_trkey']
        }}
        debounceTime={400}
        minQueryLength={1}
        clearOnEmpty
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
    <Grid container rowSpacing={1} justifyContent='center'>
      <Title />
      <Row1 {...props} />
    </Grid>
  </Box>

export default SearchForm
