import React from 'react'
import { Icon, SingleOptionSelectorComponent } from '@front/squirtle'
import { Grid } from '@mui/material'

const ExtraControls = (props) => {
  const { options, isMinimized } = props

  return (
    <Grid container item xs={12} justifyContent={'center'}>
      {
        _.map(options, (option) => (
          <Grid key={option.label} item xs={isMinimized ? 12 : 6} display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
            {!!option.icon && <Icon icon={option.icon} />}
            <SingleOptionSelectorComponent
              optionLabel={isMinimized ? null : option.label}
              value={option.value}
              onChange={option.onChange}
              getOptionLabelProps={() => ({
                componentsProps: { typography: { sx: { fontSize: 13, fontWeight: 600 } } },
                labelPlacement: isMinimized ? 'end' : 'bottom'
              })}
            />
          </Grid>
        ))
      }
    </Grid>
  )
}
export default React.memo(ExtraControls)
