import React from 'react'
import { Route, Routes } from '@front/squirtle'

import User from './Details'
import UserList from './List'
import Reviews from './Reviews'

const UserPage = props =>
  <Routes>
    <Route index element={<UserList />} />
    <Route path='create' element={<User />} />
    <Route path='reviews' element={<Reviews />} />
    <Route path=':user_id' element={<User />} />
  </Routes>


export default React.memo(UserPage)
