import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box } from '@mui/material'
import { TextField, OptionSelectorField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('telephonyprofile.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={12} justifyContent='center' spacing={2}>
    <Grid item xs={5}>
      <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} />
    </Grid>
    <Grid item xs={5}>
      <OptionSelectorField
        name='status'
        label={I18n.t('status.label', { count: 1 })}
        options={[
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.inactive'), value: 'inactive' }
        ]}
        getOptionLabelProps={() => ({ labelPlacement: 'end' })}
        multiple
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12} sm={6}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container rowSpacing={3}>
          <Title />
          <Row1 />
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(SearchForm)
