import React from 'react'
import { hooks } from '@front/volcanion'

import { Box } from '@mui/material'

import ScheduleDetail from '@abra/frames/Schedule'

const ScheduleDetailPage = props => {
  const { schedule_id } = hooks.useParams()
  return (
    <Box className='safearea'>
      <ScheduleDetail record_id={schedule_id} />
    </Box>
  )
}
export default React.memo(ScheduleDetailPage)
