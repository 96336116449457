import React from 'react'
import { Route, Routes } from '@front/squirtle'

import TrafficAlertProfile from './Details'
import TrafficAlertList from './List'

const TrafficAlertPage = props =>
  <Routes>
    <Route index element={<TrafficAlertList />} />
    <Route path='/create' element={<TrafficAlertProfile />} />
    <Route path='/:traffic_id' element={<TrafficAlertProfile />} />
  </Routes>

export default React.memo(TrafficAlertPage)
