import React from 'react'
import { Route, Routes } from '@front/squirtle'

import Staff from './Details'
import StaffList from './List'

const StaffPage = props =>
  <Routes>
    <Route index element={<StaffList />} />
    <Route path='/create' element={<Staff />} />
    <Route path='/:user_id' element={<Staff />} />
  </Routes>

export default React.memo(StaffPage)
