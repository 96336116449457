import React, { useCallback } from 'react'
import { hooks, ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { taxi_counter_id } = hooks.useParams()
  const search_params = hooks.useSearchParams()
  const commercialpackage_id = _.head(_.get(search_params, 'commercialpackage_id'))

  const formProps = {
    name: 'taxicounter_form',
    model_name: 'taxicounter',
    record_id: taxi_counter_id,
    related_states: [],
    related_searches: [],
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(commercialpackage_id), [commercialpackage_id]),
    recordToState: useCallback(Callbacks.recordToStateHandler(commercialpackage_id), [commercialpackage_id]),
    formToRecord: useCallback(Callbacks.formToRecordHandler(taxi_counter_id), [taxi_counter_id,]),
    populate: [
      'commercial_packages',
      'pricing'
    ],

    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), [])
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
