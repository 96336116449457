import React, { useMemo } from 'react'
import { I18n } from '@front/volcanion'

import { GridStepper } from '@front/squirtle'
import { Step, Box, Grid, Button } from '@mui/material'

import FormHeader, { Actions } from '@abra/elements/headers/Form'
import FormSpeedDial from '@abra/elements/speedDial/Form'
import PrimarySection from './Section/Primary'
import LoginSection from '@abra/frames/LoginSection'
import Popup from './Popup'

const StaffDetails = ({ isManagingRecord, isReadOnly, openDialog, has_2fa, onDisable2fa, ...rest }) => {

  const actions = useMemo(() => ([
    { name: I18n.t('password.reset.operator.label', { count: 1 }), iconName: 'password', onClick: () => openDialog('reset_password'), hidden: !isManagingRecord || !isReadOnly },
  ]), [isManagingRecord, isReadOnly])

  return <>
    <Popup />
    <Box className='safearea'>
      <Grid container justifyContent='center' rowSpacing={3}>
        <Grid item xs={10} >
          <FormHeader labelKey={'staff'} goBackPath={'/staff'} >
            <Actions>
              {!!has_2fa && (
                <Button variant='contained' onClick={() => onDisable2fa()} >
                  {I18n.t('2fa_disable')}
                </Button>
              )}
            </Actions>
          </FormHeader>
        </Grid>
        <Grid item xs={10} >
          <GridStepper groupsCount={[3]} activeStep={0} orientation="vertical">
            <Step> <PrimarySection {...rest} /></Step>
            <Step> <LoginSection {...rest} /></Step>
          </GridStepper>
        </Grid>
      </Grid>
    </Box>
    <FormSpeedDial actions={actions} />
  </>
}

export default React.memo(StaffDetails)
