import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row, ...props }) => <>
  <TableCell size={'small'}><TextCell value={_.get(row, 'name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={!!_.get(row, 'active') ? I18n.t('status.active') : I18n.t('status.inactive')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.join(_.compact([_.get(row, 'operator.info.first_name'), _.get(row, 'operator.info.last_name')]), ' ')} /></TableCell>
</>

export default React.memo(Row)
