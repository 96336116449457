import React from 'react'
import { hooks } from '@front/volcanion'

import { Box } from '@mui/material'

import TrafficAlertDetail from '@abra/frames/TrafficAlert'

const OrderDetailsPage = props => {
  const { traffic_id } = hooks.useParams()
  return (
    <Box className='safearea'>
      <TrafficAlertDetail record_id={traffic_id} />
    </Box>
  )
}
export default React.memo(OrderDetailsPage)
