import React from 'react'
import { I18n } from '@front/volcanion/'

import { TextField, ModelAutocompleteField } from '@front/squirtle/'
import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

const Row1 = props =>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs={3}>
      <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} disabled />
    </Grid>
    <Grid item xs={5}>
      <TextField name={'description'} label={I18n.t('description.label', { count: 1 })} multiple rows={2} />
    </Grid>
    <Grid item xs={2}>
      <ModelAutocompleteField
        name='category'
        label={I18n.t('category.label', { count: 1 })}
        model_name='settingcategory'
        labelKeys={['name']}
        searchMode={'search'}
        loadOnMount
        loadOnFocus
      />
    </Grid>
    <Grid item xs>
      <TextField name={'value'} label={I18n.t('value.label')} disabled />
    </Grid>
  </Grid>

const PrimarySection = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('info.general')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
      <Grid container rowSpacing={3}>
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
