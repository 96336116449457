import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell><TextCell value={_.get(row, 'name')} /></TableCell>
  <TableCell><TextCell value={_.join(_.compact(_.map(_.get(row, 'commercial_packages'), 'name_translated')), ', ')} /></TableCell>
  <TableCell><TextCell value={I18n.t(`load.${_.get(row, 'applicable_load')}.label`, { count: 1 })} /></TableCell>
  <TableCell><TextCell value={_.get(row, 'start_zone.name')} /></TableCell>
</>

export default React.memo(Row)
