import React from 'react'

import { MapFactory } from '@front/squirtle'
import { I18n } from '@front/volcanion'
import { JobForm, VehicleForm } from './Control/Form'
import VehicleLayers from './Layers/Vehicle'
import JobLayers from './Layers/Job'

const HomePage = ({ vehicles, jobs, onSubmitJob, onSubmitVehicle, ...props }) =>
  <MapFactory.MapContainer className='full-screen-map' mapId='supervisionScreen'>
    <VehicleLayers ids={vehicles} />
    <JobLayers ids={jobs} />
    <MapFactory.LayersControl position='topleft' >
      <MapFactory.ControlBox title={I18n.t('filter.vehicle')}      >
        <VehicleForm onSubmit={onSubmitVehicle} />
      </MapFactory.ControlBox>
    </MapFactory.LayersControl>
    <MapFactory.LayersControl position='topleft' >
      <MapFactory.ControlBox title={I18n.t('filter.order')}>
        <JobForm onSubmit={onSubmitJob} />
      </MapFactory.ControlBox>
    </MapFactory.LayersControl>
  </MapFactory.MapContainer>


export default React.memo(HomePage)
