class Callbacks {

  static filterApprochingVehiclesHandler() {
    return function filterApprochingVehicles(vehicle) {
      const current_steps = _.filter(_.get(vehicle, 'current_job.steps'), ['index', _.get(vehicle, 'current_job.current_index')])
      const approching_steps = _.filter(current_steps, ['order.assigned_transport.status', 'started'])
      return _.get(vehicle, 'status') === 'in_use' && !_.isEmpty(approching_steps)
    }
  }

  static filterArrivingVehiclesHandler() {
    return function filterArrivingVehicles(vehicle) {
      const current_steps = _.filter(_.get(vehicle, 'current_job.steps'), ['index', _.get(vehicle, 'current_job.current_index')])
      const arriving_steps = _.filter(current_steps, (step) => _.includes(['on_board', 'at_src'], step?.order?.assigned_transport?.status))
      return _.get(vehicle, 'status') === 'in_use' && !_.isEmpty(arriving_steps)
    }
  }
}

export default Callbacks
