import React from 'react'

import { I18n } from '@front/volcanion/'
import { Box, StepLabel, StepContent, Typography } from '@mui/material'

import TranslationPanel from '@abra/panels/Translation'

const TranslationSection = (props) => <>
  <StepLabel>
    <Typography variant='h5'> {I18n.t('translation', { count: 2 })} </Typography>
  </StepLabel>
  <StepContent>
    <Box sx={{ p: 2, pl: 0, boxShadow: 6, borderRadius: 3 }}>
      <TranslationPanel
        translations={[{ name: 'name_trkey', labelKey: 'name.label.one', xs: 3, required: true }]}
      />
    </Box>
  </StepContent>
</>


export default React.memo(TranslationSection)
