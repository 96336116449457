import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box } from '@mui/material'
import { TextField, ModelMenuSelectorField, OptionSelectorField } from '@front/squirtle'

const Title = props => <Grid item xs={12}> <Typography variant={'h5'}> {I18n.t('traffic_alert.search')} </Typography></Grid>

const Row1 = props =>
  <Grid container item xs={10} spacing={1}>
    <Grid item xs={3}>
      <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name='zones'
        label={I18n.t('zone.label', { count: -1 })}
        model_name='zone'
        labelKeys={['name']}
        loadOnMount
        searchMode={'search'}
        multiple
      />
    </Grid>
    <Grid item xs={3}>
      <OptionSelectorField
        name='status'
        label={I18n.t('status.label', { count: 1 })}
        options={[
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.inactive'), value: 'inactive' }
        ]}
        multiple
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
    <Grid container rowSpacing={1} justifyContent='center'>
      <Title />
      <Row1 {...props} />
    </Grid>
  </Box>

export default SearchForm
