import React from 'react'

import { TableCell } from '@mui/material'
import { TextCell, StatusCell } from '@abra/elements/table/cells/basics'

import { hooks } from '@front/volcanion'

const Row = ({ row }) => {
  const [{ name: user_name }] = hooks.useCustomerInfo(row?.user_id)
  return (
    <>
      <TableCell>
        <TextCell value={_.get(row, 'auth.username')} />
      </TableCell>
      <TableCell>
        <TextCell value={user_name} />
      </TableCell>
      <TableCell>
        <StatusCell value={row?.auth} />
      </TableCell>
      <TableCell>
        <TextCell value={_.get(row, 'auth.role.name')} />
      </TableCell>
    </>
  )
}

export default React.memo(Row)
