import React from 'react'

import { Step, Box, Grid } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import TranslationSection from './Section/Translation'
import SettingSection from './Section/Setting'

const SettingDetails = props => <>
  <Box className={'safearea'}>
    <Grid container justifyContent={'center'} rowSpacing={3}>
      <Grid item xs={10} >
        <FormHeader goBackPath={'/vehicletype'} />
      </Grid>
      <Grid item xs={10} >
        <GridStepper groupsCount={[2]} activeStep={0} orientation={'vertical'}>
          <Step> <TranslationSection {...props} />  </Step>
          <Step> <SettingSection {...props} />  </Step>
        </GridStepper>
      </Grid>
    </Grid>
  </Box>
</>

export default React.memo(SettingDetails)
