import React from 'react'
import { Route, Routes } from '@front/squirtle'

import Setting from './Details'
import SettingList from './List'

const SettingPage = props =>
  <Routes>
    <Route index element={<SettingList />} />
    <Route path='/create' element={<Setting />} />
    <Route path='/:setting_id' element={<Setting />} />
  </Routes>

export default React.memo(SettingPage)
