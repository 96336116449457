import React from 'react'
import { hooks } from '@front/volcanion'

import { Box } from '@mui/material'

import VehicleDetails from '@abra/frames/Vehicle'

const CompanyContractPage = props => {
  const { group_id, vehicle_id } = hooks.useParams()
  return (
    <Box className='safearea' sx={{ display: 'flex', justifyContent: 'center' }}>
      <VehicleDetails record_id={vehicle_id} group_id={group_id} />
    </Box>
  )
}

export default React.memo(CompanyContractPage)
