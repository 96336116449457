import React from 'react'

import { TableCell } from '@mui/material'
import { OrderLink, UserLink, DriverLink, VehicleLink } from '@abra/elements'
import { TextCell } from '@abra/elements/table/cells/basics/'
import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row }) => {
  return (
    <>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={14} value={FormatUtils.formatDateBackToFront(row?.rating?.createdAt)} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <OrderLink fontSize={14} record_id={row?.order_id} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <UserLink fontSize={14} record_id={row?.do?.user_id} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={14} value={row?.service?.contract?.company?.name} />
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={14} value={!!row?.service
          ? row?.service?.contract?.formula.name
          : row?.do?.commercial_formula?.name
        } />
      </TableCell>
      <TableCell size='small' padding='none'>
        {!!row?.assigned_transport?.driver ?
          <DriverLink fontSize={14} record_id={row?.assigned_transport?.driver} /> : '-'
        }
      </TableCell>
      <TableCell size='small' padding='none'>
        {!!row?.assigned_transport?.driver && row?.assigned_transport?.vehicle?.vehicle_id ?
          <VehicleLink fontSize={14} record_id={row?.assigned_transport?.vehicle?.vehicle_id} /> : '-'
        }
      </TableCell>
      <TableCell size='small' padding='none'>
        <TextCell fontSize={14} value={row?.rating?.rating} />
      </TableCell>
      <TableCell size='small' padding='none' sx={{ maxWidth: 500 }}>
        <TextCell fontSize={14} value={row?.rating?.comment} />
      </TableCell>
    </>
  )
}

export default React.memo(Row)
