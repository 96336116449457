import React from 'react'
import { hooks } from '@front/volcanion'

import { Box } from '@mui/material'

import OptionDetails from '@abra/frames/Option'

const UserOptionPage = props => {
  const { user_option_id } = hooks.useParams()
  return (
    <Box className='safearea' sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: 2 / 3 }}>
        <OptionDetails record_id={user_option_id} model_name='useroption' />
      </Box>
    </Box>
  )
}

export default React.memo(UserOptionPage)
