import React from 'react'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row }) => {
  return <>
    <TableCell size={'small'}> <TextCell value={row?.name_translated} /> </TableCell>
    <TableCell size={'small'}> <TextCell value={row?.search_range} /> </TableCell>
    <TableCell size={'small'}> <TextCell value={row?.approach_time} /> </TableCell>
  </>
}

export default React.memo(Row)
