import React from 'react'
import { FormDialog, DialogContent } from '@front/squirtle'

import ChangePassword from './ChangePassword'
import { SuspensionPopupForm } from '@abra/elements/Suspensions'

const Popups = ({
  onConfirmPassword,
  user_auth_id,
}) => (
  <>
    <SuspensionPopupForm record_id={user_auth_id} />
    <FormDialog name={'reset_password'} formProps={{ onSubmit: onConfirmPassword }}>
      <DialogContent>
        <ChangePassword />
      </DialogContent>
    </FormDialog>
  </>
)

export default React.memo(Popups)
