import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import Callbacks from './callbacks'

const TaxicounterList = props => {
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])

  return (
    <Box className={'safearea'}>
      <Grid container justifyContent='center'>
        <Grid item xs={11}>
          <Box sx={{ mt: 2 }}>
            <SearchFrame
              model_name={'taxicounter'}
              populate={['commercial_packages', 'start_zone']}
              createButtonProps={{
                label: I18n.t('taxicounter.add', { count: 1 })
              }}
            >
              <SearchForm
                submitOnMount
                formToFilter={formToFilter}
              >
                <FormContent> <Form /> </FormContent>
              </SearchForm>
              <SearchTable tableProps={{ placementPagination: 'bottom' }}>
                <TableHeader><Header /></TableHeader>
                <TableRow><Row /></TableRow>
              </SearchTable>
            </SearchFrame>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}


export default React.memo(TaxicounterList)
