import React from 'react'
import { Route, Routes } from '@front/squirtle'

import UserOption from './Details'
import UserOptionList from './List'

const UserOptionPage = props =>
  <Routes>
    <Route index element={<UserOptionList />} />
    <Route path='/create' element={<UserOption />} />
    <Route path='/:user_option_id' element={<UserOption />} />
  </Routes>

export default React.memo(UserOptionPage)
