import React from 'react'
import { Route, Routes } from '@front/squirtle'

import Schedule from './Details'
import ScheduleList from './List'

const SchedulePage = props =>
  <Routes>
    <Route index element={<ScheduleList />} />
    <Route path='/create' element={<Schedule />} />
    <Route path='/:schedule_id' element={<Schedule />} />
  </Routes>

export default React.memo(SchedulePage)
