
import React from 'react'

import { Grid, Step, Box } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import PrimarySection from '@abra/pages/Pricerule/Details/Section/Primary'
import ScheduleSection from '@abra/pages/Pricerule/Details/Section/Schedule'
import GeoSection from './Section/Geo'
import RateParameterSection from '@abra/pages/Pricerule/Details/Section/RateParameter'

const Taxicounter = props => <>
  <Box className='safearea'>
    <Grid container justifyContent={'center'} rowSpacing={1}>
      <Grid item xs={8} >
        <FormHeader />
      </Grid>
      <Grid item xs={8} >
        <GridStepper groupsCount={[4]} activeStep={0} orientation={'vertical'}>
          <Step> <PrimarySection isTaxicounter />  </Step>
          <Step> <ScheduleSection /> </Step>
          <Step> <GeoSection /> </Step>
          <Step> <RateParameterSection /> </Step>
        </GridStepper>
      </Grid>
    </Grid>
  </Box>
</>

export default React.memo(Taxicounter)
