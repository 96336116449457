import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align='center'><TextHeader label={I18n.t('type.label', { count: 1 })} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('name.label', { count: 1 })} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('quotation.marge')} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('status.label', { count: 1 })} /></TableCell>
</>

export default React.memo(Header)
