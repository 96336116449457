import React, { useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const UserList = props => {
  const CODE_INTERNATIONAL = hooks.useRelayConstant('CODE_INTERNATIONAL')

  const filterToForm = useCallback(Callbacks.filterToFormHandler(CODE_INTERNATIONAL), [CODE_INTERNATIONAL])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(CODE_INTERNATIONAL), [CODE_INTERNATIONAL])

  return (
    <Box className={'safearea'}>
      <Grid container justifyContent='center' alignItems='center' sx={{ bgcolor: 'background.primary' }}>
        <Grid item xs={11}>
          <SearchFrame
            model_name={'user'}
            populate={[
              'customerinfo',
              'info',
              'auth'
            ]}
            createButtonProps={{
              label: I18n.t('user.add')
            }}
          >
            <SearchForm
              filterToForm={filterToForm}
              formToFilter={formToFilter}
              submitOnMount
            >
              <FormContent><Form /></FormContent>
            </SearchForm >
            <SearchTable>
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame >
        </Grid >
      </Grid >
    </Box >
  )
}

export default React.memo(UserList)
