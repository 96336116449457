
import React from 'react'

import { Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import PrimarySection from './Section/Primary'

const RateParameter = ({ disable_name, ...props }) =>
  <Grid container justifyContent={'center'} rowSpacing={1}>
    <Grid item xs={8} >
      <FormHeader />
    </Grid>
    <Grid item xs={8} >
      <GridStepper groupsCount={[1]} activeStep={0} orientation={'vertical'}>
        <Step> <PrimarySection disable_name={disable_name} /> </Step>
      </GridStepper>
    </Grid>
  </Grid >

export default React.memo(RateParameter)
