class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record,
        value: JSON.stringify(_.get(record, 'value'))
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      return {
        ...values
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name')
      }
    }
  }
}

export default Callbacks
