import React from 'react'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

import FormatUtils from '@front/squirtle/utils/format'

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell size={'small'}><TextCell value={row?.type?.name_translated} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'info.plaque')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'info.model.brand.name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'info.model.name')} /></TableCell>
  <TableCell size={'small'}><TextCell value={FormatUtils.formatDateBackToFront(_.get(row, 'info.in_service_date'), 'DD/MM/YYYY')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'parent_license.license_number')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'parent_license.license_client_id')} /></TableCell>
  <TableCell size={'small'}><TextCell value={_.get(row, 'group.group_client_id')} /></TableCell>
</>

export default React.memo(Row)
