import React from 'react'
import { hooks } from '@front/volcanion'

import { Box } from '@mui/material'

import SpecificParameterForm from '@abra/frames/SpecificParameter'

const SpecificParameterPage = props => {
  const { specificparameter_id } = hooks.useParams()
  return (
    <Box sx={{ pr: 6, pt: 2 }}>
      <SpecificParameterForm record_id={specificparameter_id} form_name='order_form_details' />
    </Box>
  )
}
export default React.memo(SpecificParameterPage)
