import FormatUtils from '@front/squirtle/utils/format'

class Callbacks {
  static formToRecordHandler(user_id) {
    return function formToRecord(values, extra, meta, { suspended, ...state }) {
      return {
        ...values,
        staffinfo: { app_version: null },
        type: 'staff',
        status: FormatUtils.parseStatus(_.get(values, 'status'), _.pick(suspended, ['suspendedUntil', 'suspendedSince', 'motive']))
      }
    }
  }

  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ...record,
        status: FormatUtils.getStatus(_.pick(_.get(record, 'auth'), ['active', 'deleted', 'suspendedUntil', 'suspendedSince']))
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        user_auth_id: record?.auth?.user_auth_id,
        title: _.get(record, 'auth.username'),
        suspended: {
          suspendedUntil: record?.auth?.suspendedUntil,
          suspendedSince: record?.auth?.suspendedSince,
          motive: record?.auth?.motive
        }
      }
    }
  }

  static onSubmitSuccessHandler(openDialog, user_id) {
    return async function onSubmitSuccess(result, values, extra, meta) {
      if (!!user_id) return
      return openDialog('reset_password')
    }
  }
}

export default Callbacks
