import React from 'react'

import { Step, Box, Grid } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'
import PrimarySection from './Section/Primary'

const TelephonyProfileDetails = props => <>
  <Box className='safearea'>
    <Grid container justifyContent='center' rowSpacing={3}>
      <Grid item xs={10} >
        <FormHeader goBackPath={'/telephonyprofile'} />
      </Grid>
      <Grid item xs={10} >
        <GridStepper groupsCount={[3]} activeStep={0} orientation="vertical">
          <Step> <PrimarySection /> </Step>
        </GridStepper>
      </Grid>
    </Grid>
  </Box>
</>

export default React.memo(TelephonyProfileDetails)
