import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

const withContainer = Component => props => {
  const [password] = hooks.useFieldValues(['password'])

  const checkPassword = useCallback(value => {
    if (!value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/g)) return I18n.t('error.password.format')
    else return
  }, [])

  const checkConfirmPassword = useCallback(value => {
    if (value !== password) return I18n.t('error.password.not_same')
    else return
  }, [password])

  const mergedProps = {
    checkPassword,
    checkConfirmPassword
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
