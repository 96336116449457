import { FormatUtils } from "@front/volcanion/utils"

class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        status: ['active', 'deleted', 'suspended'],
        ...filter
      }
    }
  }

  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      const { status, ...rest } = values || {}
      return {
        ...rest,
        staffinfo: { '!': null },
        auth: FormatUtils.getParseFilterStatus(status),
      }
    }
  }
}

export default Callbacks
