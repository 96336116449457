class Callbacks {
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      return {
        ...values
      }
    }
  }
}

export default Callbacks
