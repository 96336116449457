import _ from "lodash"

function getCPFilter(load_type, commercial_package_id) {
  if (!_.isEmpty(load_type) && !_.isEmpty(commercial_package_id))
    return {
      first_step: {
        order: {
          commercial_package: {
            commercial_package_id,
            load_type
          }
        }
      }
    }
  else if (!_.isEmpty(load_type))
    return {
      first_step: {
        order: {
          commercial_package: {
            load_type
          }
        }
      }
    }
  else if (!_.isEmpty(commercial_package_id))
    return {
      first_step: {
        order: {
          commercial_package: {
            commercial_package_id
          }
        }
      }
    }
  else return {}
}
class Callbacks {
  static onSubmitJobHandler(searchJobs, fleets) {
    return async function onSubmitJob(values) {
      const load_type = !_.isEmpty(fleets) ? _.uniq(_.compact(_.map(fleets, 'load_type'))) : []
      const commercial_package_id = !_.isEmpty(_.get(values, 'commercial_package')) ? _.get(values, 'commercial_package') : []
      const filter = getCPFilter(load_type, commercial_package_id)
      return searchJobs(filter)
    }
  }

  static onSubmitVehicleHandler(searchVehicles) {
    return async function onSubmitVehicle(values) {
      return searchVehicles(_.merge({},
        _.isEmpty(_.get(values, 'vehicle_options')) ? {} : {
          options: _.get(values, 'vehicle_options'),
        },
        _.isEmpty(_.get(values, 'user_options')) ? {} : { driver: { options: _.get(values, 'user_options') } }
      ))
    }
  }
}

export default Callbacks
