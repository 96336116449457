import React from 'react'
import { Route, Routes } from '@front/squirtle'

import Vehicle from './Details'
import VehicleList from './List'

const VehiclePage = props =>
  <Routes>
    <Route index element={<VehicleList />} />
    <Route path=':vehicle_id' element={<Vehicle />} />
    <Route path='create' element={<Vehicle />} />
  </Routes>


export default React.memo(VehiclePage)
