import React from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

import { usePhoneCall } from '@abra/hooks/ivr'
import HookUtils from '@front/volcanion/utils/hooks'

const withContainer = Component => ({ vehicle_id }) => {
  const onCall = usePhoneCall()
  const [vehicle, vehicleState] = hooks.useModel('vehicle', [vehicle_id], {
    populate: [
      'type',
      'info.model.brand',
      'options',
      'driver.info',
      'driver.auth',
      'driver.options',
      'current_job.first_step.order'
    ],
    single: true
  })

  return (
    <Loader isLoading={HookUtils.getLoadingState([vehicleState])}>
      <Component vehicle={vehicle} onCall={onCall} />
    </Loader>
  )
}

export default withContainer
