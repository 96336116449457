import React from 'react'
import { CDNSVG, hooks, I18n } from '@front/volcanion'
import { ModelAutocompleteComponent, Icon } from '@front/squirtle'
import DispatchErrors from '@abra/elements/Dispatch/Errors'
import { Box, Divider, Grid, Button, Link, Typography, Chip, IconButton } from '@mui/material'

import UserUtils from '@front/volcanion/utils/user'

const CardItem = ({ value, icon, isLink, href, sx, children, ...props }) =>
  <Grid item xs={12} hidden={!value}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon color='primary' icon={icon} />
      {!isLink &&
        <Typography sx={{ m: 'unset !important', pl: 1, fontSize: 13, ...sx }} {...props}>{value}</Typography>
      }
      {!!isLink &&
        <Link color='white' target='_blank' href={href}>
          <Typography color='primary' sx={{ m: 'unset !important', pl: 1, fontSize: 13, ...sx }} {...props}> {value}</Typography>
        </Link>
      }
      {children}
    </Box>
  </Grid >

const OrderTitle = ({ job }) => {
  const order_status = hooks.useOrderStatus(_.get(job, 'first_step.order'), { translated: true })
  const color = hooks.useOrderStatusColor(_.get(job, 'first_step.order'))

  return (
    <Grid container item xs={12} rowSpacing={2}>
      <Grid item xs={12}><Divider /></Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='h5' sx={{ m: 'unset !important', fontSize: 'subtitle1.fontSize' }}> {I18n.t('sqr.order.info')}</Typography>
          <Chip size='small' label={order_status} sx={{ ml: 2, fontWeight: 600, opacity: 0.8, bgcolor: color, color: 'white' }} />
        </Box>
      </Grid>
      <Grid item xs={12}><Divider /></Grid>
    </Grid>
  )
}

const DispatchTitle = props =>
  <Grid container item xs={12} rowSpacing={2}>
    <Grid item xs={12}><Divider /></Grid>
    <Grid item xs={12}>
      <Typography variant='h5' sx={{ m: 'unset !important', textAlign: 'center', fontSize: 'subtitle1.fontSize' }}> {I18n.t('sqr.dispatch.label', { count: 1 })}</Typography>
    </Grid>
    <Grid item xs={12}><Divider /></Grid>
  </Grid>

const JobCard = ({
  job,
  driverId,
  setDriverId,
  onClick,
  hasTransport,
  onCall,
  current_driver_name,
  onDispatchCancelClick,
  order_id,
}) =>
  <Grid container rowSpacing={2}>
    <OrderTitle job={job} />
    <CardItem
      icon='bookmark_border'
      value={_.get(job, 'first_step.order.order_client_id')}
      href={`/order/${_.get(job, 'first_step.order.order_id')}`}
      isLink
    />

    <CardItem icon={'radio_button_checked'} value={_.get(job, 'first_step.order.source.name')} />
    <CardItem icon={'radio_button_checked'} iconColor='error' value={_.get(job, 'last_step.order.destination.name')} />

    <CardItem icon={'person'} value={_.get(job, 'first_step.order.loads[0].src_contact')} isLink href={`/user/${_.get(job, 'first_step.order.do')}`} />
    <CardItem icon={'phone'} value={_.get(job, 'first_step.order.loads[0].src_phone')} >
      <IconButton onClick={() => onCall(_.get(job, 'first_step.order.loads[0].src_phone'))} >
        <Box sx={{ color: 'primary.main', fontSize: 33 }}><CDNSVG name='customer-call' /></Box>
      </IconButton>
    </CardItem>
    {!hasTransport ? (
      <Grid container item xs={12} rowSpacing={2}>
        <DispatchTitle />
        <Grid item xs={12}>
          <ModelAutocompleteComponent
            label='ID / Nom chauffeur'
            value={driverId}
            onChange={setDriverId}
            model_name={'user'}
            searchMode='search'
            searchQuery={'='}
            autocompleteMutator={q => ({
              or: [
                { info: { user_client_id: _.split(q, ' ') } },
                { info: { first_name: _.split(q, ' ') } },
                { info: { last_name: _.split(q, ' ') } }]
            })}
            labelKeys={['label']}
            config={{
              forced_filter: { driverinfo: { '!': null }, status: [{ name: 'active' }] },
              populate: ['info', 'customerinfo'],
              mutator: user => ({
                label: UserUtils.getFormatedName({ ...user?.info, designation: user?.customerinfo?.name }, { prefix: user?.info?.user_client_id }),
                ...user
              })
            }}
            selectSingleOption
            debounceTime={600}
          />
        </Grid>
        <Grid item xs={12}>
          <DispatchErrors order_id={order_id} driver_id={driverId} />
        </Grid>
        <Grid item xs >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              color='primary'
              size='large'
              sx={{ fontSize: 'subtitle.fontSize' }}
              onClick={onClick}
              variant='contained'
            >
              {I18n.t('sqr.dispatch.action')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    ) : (
      <Grid container item xs={12}>
        <DispatchTitle />
        <Grid item xs={12}>
          <CardItem icon={'local_taxi'} value={current_driver_name} >
            <IconButton onClick={() => onDispatchCancelClick(job.job_id)} >
              <Box sx={{ color: 'primary.main', fontSize: 33, flex: 1 }}><Icon icon='clear' /></Box>
            </IconButton>
          </CardItem>
        </Grid>
      </Grid>
    )}
  </Grid>


export default React.memo(JobCard)

