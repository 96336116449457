class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return filter
    }
  }
  static formToFilterHandler(group) {
    return function formToFilter(values, extra, meta, state) {
      return {
        group,
        info: {
          ...!!values?.info?.plaque && { plaque: { contains: values?.info?.plaque } },
          model: {
            vehiclemodel_id: _.get(values, 'model'),
            brand: _.get(values, 'brand'),
          },
          in_service_date: _.get(values, 'info.in_service_date')
        },
        parent_license: {
          license_number: _.get(values, 'parent_license.license_number'),
          license_client_id: _.get(values, 'parent_license.license_client_id'),
        },
        options: _.get(values, 'options'),
        type: values?.type
      }
    }
  }
}

export default Callbacks
