import React from 'react'
import { Route, Routes } from '@front/squirtle'

import VehicleOption from './Details'
import VehicleOptionList from './List'

const VehicleOptionPage = props =>
  <Routes>
    <Route index element={<VehicleOptionList />} />
    <Route path='/create' element={<VehicleOption />} />
    <Route path='/:vehicle_option_id' element={<VehicleOption />} />
  </Routes>

export default React.memo(VehicleOptionPage)
