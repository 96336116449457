class Callbacks {
  static filterToFormHandler(company_id) {
    return function filterToForm(filter) {
      return {
        company_id,
        ...filter
      }
    }
  }
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      return _.merge({},
        values,
        _.has(values, 'specific.name') ? { specific: { name: { contains: values?.specific?.name } } } : {},
        { specific: { generic: true } },
      )
    }
  }
}

export default Callbacks
