import React from 'react'
import { Route, Routes } from '@front/squirtle'

import RateParameter from './Details'
import RateParameterList from './List'

const RateParameterPage = props =>
  <Routes>
    <Route index element={<RateParameterList />} />
    <Route path='/create' element={<RateParameter />} />
    <Route path='/:rateparameter_id' element={<RateParameter />} />
  </Routes>


export default React.memo(RateParameterPage)
