import React, { useCallback } from 'react'
import { ModelForm, hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { telephonyprofile_id } = hooks.useParams()
  const { openNotification } = hooks.useNotification()

  const formProps = {
    name: 'telephonyprofile_form',
    model_name: 'telephonyprofile',
    record_id: telephonyprofile_id,
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(openNotification), [openNotification]),
    populate: ['operator.info'],
    initialState: { isReadOnly: !!telephonyprofile_id }
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
