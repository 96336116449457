import React, { useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Grid, Box } from '@mui/material'

import Callbacks from './callbacks'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const SpecificParameterList = props => {
  const navigate = hooks.useNavigate()
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])

  return (
    <Box className={'safearea'}>
      <Grid container justifyContent={'center'}>
        <Grid item xs={10}>
          <SearchFrame
            model_name={'commercialpackagespecificparameters'}
            populate={['commercialpackage', 'specific']}
            createButtonProps={{
              label: I18n.t('specificparameter.create')
            }}
          >
            <SearchForm
              filterToForm={filterToForm}
              formToFilter={formToFilter}
              submitOnMount
            >
              <FormContent><Form /></FormContent>
            </SearchForm>
            <SearchTable tableProps={{
              onRowClick: (rowID, row) => navigate(_.get(row, 'specific.specificparameter_id'))
            }}>
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame>
        </Grid>
      </Grid>
    </Box>
  )
}


export default React.memo(SpecificParameterList)
