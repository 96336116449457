import { I18n } from "@front/volcanion"
class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        name: _.get(record, 'name'),
        operator: _.join(_.compact([_.get(record, 'operator.info.first_name'), _.get(record, 'operator.info.last_name')]), ' '),
        active: _.get(record, 'active')
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        active: true
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta, state) {
      return {
        name: _.get(values, 'name'),
        active: _.get(values, 'active')
      }
    }
  }

  static onSubmitFailedHandler(openNotification) {
    return async function onSubmitFailed(err, values, extra, meta) {
      if (_.get(err, 'code') === 'RECORD_EXISTS' && _.get(err, 'context.key') === 'name')
        return openNotification(I18n.t('error.telephonyprofile.name'), { variant: 'error' })
      return openNotification(I18n.t('eorr.unknow'), { variant: 'error' })
    }
  }
}

export default Callbacks
