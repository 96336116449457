import React from 'react'
import { Route, Routes } from '@front/squirtle'

import TelephonyProfile from './Details'
import TelephonyProfileList from './List'

const TelephonyProfilePage = props =>
  <Routes>
    <Route index element={<TelephonyProfileList />} />
    <Route path='/create' element={<TelephonyProfile />} />
    <Route path='/:telephonyprofile_id' element={<TelephonyProfile />} />
  </Routes>

export default React.memo(TelephonyProfilePage)
