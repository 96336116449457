import _ from "lodash"

class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return _.merge({}, filter, { status: ['active', 'inactive'] })
    }
  }

  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      const new_values = _.omit(values, ['status'])
      return _.merge({}, new_values,
        _.get(values, 'status', []).length === 1 ? { active: _.includes(values.status, 'active') } : {})
    }
  }
}

export default Callbacks
