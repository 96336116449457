import React from 'react'
import { Route, Routes } from '@front/squirtle'

import SpecificParameter from './Details'
import SpecificParameterList from './List'

const SpecificParameterPage = props =>
  <Routes>
    <Route index element={<SpecificParameterList />} />
    <Route path='/create' element={<SpecificParameter />} />
    <Route path='/:specificparameter_id' element={<SpecificParameter />} />
  </Routes>

export default React.memo(SpecificParameterPage)
