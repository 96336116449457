import React from 'react'

import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Grid, Box } from '@mui/material'

import Form from './Form'
import Header from './Header'
import Row from './Row'

const ScheduleList = props => <>
  <Box className='safearea'>
    <Grid container justifyContent='center'>
      <Grid item xs={11}>
        <SearchFrame
          model_name={'schedule'}
          createButtonProps={{ label: I18n.t('schedule.add', { count: 1 }) }}
        >
          <SearchForm submitOnMount>
            <FormContent><Form /></FormContent>
          </SearchForm>
          <SearchTable>
            <TableHeader><Header /></TableHeader>
            <TableRow><Row /></TableRow>
          </SearchTable>
        </SearchFrame>
      </Grid>
    </Grid>
  </Box>
</>

export default React.memo(ScheduleList)
