import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'
import { TextField, ModelMenuSelectorField } from '@front/squirtle'

import CPUtils from '@front/squirtle/utils/CPUtils'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('specificparameter.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid container item xs={11} justifyContent='center' spacing={1}>
    <Grid item xs={5}>
      <TextField name={'specific.name'} label={I18n.t('name.label', { count: 1 })}
      />
    </Grid>
    <Grid item xs={7}>
      <ModelMenuSelectorField
        name={'commercialpackage'}
        label={I18n.t('commercialpackage.label', { count: 1 })}
        model_name='commercialpackage'
        labelKeys={['label']}
        loadOnMount
        searchMode={'search'}
        config={{
          mutator: cp => ({ ...CPUtils.getTranslatedLabel(cp), ...cp })
        }}
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12} sm={6}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container rowSpacing={3} justifyContent={'center'}>
          <Title />
          <Row1 />
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(SearchForm)
