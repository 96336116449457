
import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'
import Callbacks from './callbacks'


const withContainer = Component => (props) => {
  const [user_auth_id] = hooks.useFormState('user_auth_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')

  const [supported_languages] = hooks.useSetting('supported_languages')
  const [status] = hooks.useFieldValues(['status'])
  const [, { openDialog }] = hooks.useDialogs()
  const [, setSuspendedState] = hooks.useFormState('suspended')

  const statusChange = useCallback(Callbacks.statusChangeHandler(openDialog, setSuspendedState), [openDialog, setSuspendedState])
  const verifyUsername = useCallback(hooks.useRecordExist('user', 'auth.username'), [])
  const verifyEmail = useCallback(hooks.useRecordExist('user', 'auth.email', { staffinfo: { '!': null } }), [])

  const color = useMemo(() => {
    if (!!_.includes(['inactive', 'archived', 'suspended'], status)) return 'orange'
    else if (status === 'active') return 'green'
    else return 'red'
  }, [status])

  const mergedProps = {
    user_auth_id,
    supported_languages,
    isManagingRecord,
    color,
    verifyUsername,
    statusChange,
    verifyEmail,
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer

