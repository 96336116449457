import _ from 'lodash'
import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import HookUtils from '@front/volcanion/utils/hooks'

import Callbacks from './callbacks'
const withContainer = Component => props => {
  const setFormValues = hooks.useGlobalFormChange('job_search')
  const setVehicleFormValues = hooks.useGlobalFormChange('vehicle_search')
  const [fleet_ids, cp_ids, cp_option] = hooks.useGlobalFieldValues('job_search', ['fleet', 'commercial_package', 'cp_option'])
  const [user_options, vehicle_options] = hooks.useGlobalFieldValues('vehicle_search', ['user_options', 'vehicle_options'])
  const [fleets] = hooks.useModel('fleet', fleet_ids)
  const [commercial_packages] = hooks.useModel('commercialpackage', cp_ids, { populate: ['default_driver_options', 'default_vehicle_options'] })
  const load_type = _.uniq(_.compact(_.map(fleets, 'load_type')))
  const requestSubmit = hooks.useGlobalFormFunction('job_search', 'requestSubmit')

  const cp_watchers = [_.join(_.compact(load_type), ',')]
  const fleetChange = useCallback(Callbacks.fleetChangeHandler(setFormValues, setVehicleFormValues, load_type, commercial_packages, user_options, vehicle_options),
    [setFormValues, HookUtils.getRecordsWatcher(fleets), HookUtils.getRecordsWatcher(commercial_packages), HookUtils.getRecordsWatcher(user_options), HookUtils.getRecordsWatcher(vehicle_options)])
  const cpChange = useCallback(Callbacks.cpChangeHandler(setVehicleFormValues, cp_ids, cp_option), [setVehicleFormValues, HookUtils.getRecordsWatcher(commercial_packages), HookUtils.getRecordsWatcher(cp_option)])
  const switchChange = useCallback(Callbacks.switchChangeHandler(setVehicleFormValues, commercial_packages), [setVehicleFormValues, HookUtils.getRecordsWatcher(commercial_packages)])

  const getCommercialPackageFilter = useCallback(() => {
    if (!_.isEmpty(load_type))
      return { load_type: _.flatten([load_type, null]) }
    return null
  }, [cp_watchers])


  const mergedProps = {
    cp_watchers,
    getCommercialPackageFilter,
    fleetChange,
    cpChange,
    switchChange,
    requestSubmit
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
