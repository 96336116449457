import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid } from '@mui/material'
import { TextField } from '@front/squirtle'

const ChangePassword = ({ checkPassword, checkConfirmPassword, ...props }) => <Box sx={{ m: 3 }}>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs={6}>
      <TextField
        name={'password'}
        label={I18n.t('password.temp')}
        type='password'
        required
        validate={checkPassword}
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        name={'confirm_password'}
        label={I18n.t('password.confirm')}
        type='password'
        required
        validate={checkConfirmPassword}
      />
    </Grid>
  </Grid>
</Box>


export default React.memo(ChangePassword)
