import React, { useState, useCallback, useMemo } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { MapFactory } from '@front/squirtle'
import LayerControl from '../../Control/Layers'

import Callbacks from './callbacks'
import VehicleCard from './Card'

const VehicleLayer = ({ ids, config, styles, displayProps, ...rest }) => {
  const [vehicles] = hooks.useModel('vehicle', ids, config)
  const {
    includeVehicles,
    includeTarget,
    includeLine
  } = displayProps

  const getVehicleStyles = useCallback((instance, records) => {
    const vehicle_type_icon = _.head(records)?.type?.icon

    return {
      circleOutlineColor: _.head(records)?.driver?.problem_type ? 'red' : 'white',
      circleScale: 1,
      iconSize: [28, 28],
      icon: !!vehicle_type_icon ? vehicle_type_icon : undefined,
      iconType: !!vehicle_type_icon ? 'cdn' : undefined
    }
  }, [])
  const getPolylineCoordinates = useCallback((record) => [_.get(record, 'current_location.coordinates'), _.get(record, 'current_location.target')], [])

  const onMouseOver = useCallback((event) => {
    const map_listener = event.target.getMapListener()
    const record = _.head(event.target.getParentListener()?.getRecords())
    const all_markers = map_listener.getChildrenListeners('marker', true)
    const all_polylines = map_listener.getChildrenListeners('polyline', true)
    const my_markers = map_listener.getChildrenListenersByModel('vehicle', record?.vehicle_id, 'marker', true)
    const my_polylines = map_listener.getChildrenListenersByModel('vehicle', record?.vehicle_id, 'polyline', true)
    const related_markers = map_listener.getChildrenListenersByModel('job', record?.current_job, 'marker', true)
    const related_polylines = map_listener.getChildrenListenersByModel('job', record?.current_job, 'polyline', true)

    _.map(all_markers, (marker) => marker?.getInstance()?.setOpacity(0.3))
    _.map(my_markers, (marker) => marker?.getInstance()?.setOpacity(1))
    _.map(related_markers, (marker) => marker?.getInstance()?.setOpacity(1))
    _.map(all_polylines, (polyline) => polyline?.removeFromParent())
    _.map(my_polylines, (polyline) => polyline?.addToParent())
    _.map(related_polylines, (polyline) => polyline?.addToParent())
  }, [])
  const onMouseOut = useCallback((event) => {
    const map_listener = event.target.getMapListener()
    const all_markers = map_listener.getChildrenListeners('marker', true)
    const all_polylines = map_listener.getChildrenListeners('polyline', true)
    _.map(all_markers, (marker) => marker?.getInstance()?.setOpacity(1))
    _.map(all_polylines, (polyline) => polyline?.addToParent())
  }, [])
  const eventHandlers = {
    mouseover: onMouseOver,
    mouseout: onMouseOut
  }

  return (
    <MapFactory.SuperLayer styles={styles} {...rest}>
      {_.map(vehicles, ({ vehicle_id }) => (
        <MapFactory.SuperLayer key={vehicle_id}>
          {
            !!includeVehicles && (
              <MapFactory.Marker
                config={{
                  model_name: 'vehicle',
                  ids: [vehicle_id],
                  populate: ['current_location', 'type'],
                  coordinates_path: 'current_location.coordinates'
                }}
                animate
                interactive
                getStyles={getVehicleStyles}
                eventHandlers={eventHandlers}
                riseOnHover
              >
                <MapFactory.Popup>
                  <VehicleCard vehicle_id={vehicle_id} />
                </MapFactory.Popup>
              </MapFactory.Marker>
            )}
          {
            !!includeLine && (
              <MapFactory.Polyline
                animate
                config={{
                  model_name: 'vehicle',
                  ids: [vehicle_id],
                  populate: ['current_location', 'type'],
                  coordinates_path: getPolylineCoordinates
                }}
                styles={{ dashArray: "4" }}
              >
                <MapFactory.Popup>
                  <VehicleCard vehicle_id={vehicle_id} />
                </MapFactory.Popup>
              </MapFactory.Polyline>
            )
          }
          {includeTarget && (
            <MapFactory.Marker
              config={{
                model_name: 'vehicle',
                ids: [vehicle_id],
                populate: ['current_location', 'type'],
                coordinates_path: 'current_location.target'
              }}
              animate
              interactive
              getStyles={getVehicleStyles}
              styles={{ icon: 'mode_standby' }}
              eventHandlers={eventHandlers}
              riseOnHover
            >
              <MapFactory.Popup>
                <VehicleCard vehicle_id={vehicle_id} />
              </MapFactory.Popup>
            </MapFactory.Marker>
          )}
        </MapFactory.SuperLayer>
      ))}
    </MapFactory.SuperLayer>
  )
}

const VehicleLayers = (props) => {
  const { ids } = props

  const [enableClustering, setEnableClustering] = useState(false)
  const [includeTarget, setIncludeTarget] = useState(true)
  const [includeLine, setIncludeLine] = useState(true)
  const [includeVehicles, setIncludeVehicles] = useState(true)
  const valueProps = {
    includeVehicles,
    includeTarget,
    includeLine,
    enableClustering
  }

  const filterApprochingVehicles = useCallback(Callbacks.filterApprochingVehiclesHandler(), [])
  const filterArrivingVehicles = useCallback(Callbacks.filterArrivingVehiclesHandler(), [])

  const options = [{
    icon: 'local_taxi',
    label: I18n.t('supervision.vehicle.options.vehicles'),
    value: includeVehicles,
    onChange: setIncludeVehicles,
  }, {
    icon: 'mode_standby',
    label: I18n.t('supervision.vehicle.options.target'),
    value: includeTarget,
    onChange: setIncludeTarget,
  }, {
    icon: 'more_horiz',
    label: I18n.t('supervision.vehicle.options.journey'),
    value: includeLine,
    onChange: setIncludeLine,
  }, {
    icon: 'donut_large',
    label: I18n.t('supervision.vehicle.options.clustering'),
    value: enableClustering,
    onChange: setEnableClustering,
  }]

  return (
    <MapFactory.Cluster disableClustering={!enableClustering} >
      <MapFactory.LayersControl position={'topright'} hasMinimize defaultMinimized>
        <MapFactory.ControlBox
          title={[I18n.t(`display.label`, { count: 1 }), _.toLower(I18n.t(`vehicle.label`, { count: 1 }))].join(' ')}
          defaultOpen
        >
          <LayerControl options={options} />
        </MapFactory.ControlBox>
        <VehicleLayer
          control={{
            name: I18n.t('supervision.vehicle.status.available'),
            group: 'vehicle',
            defaultEnabled: true
          }}
          ids={ids}
          config={{
            model_name: 'vehicle',
            postFilter: useMemo(() => ['status', 'available'], []),
            populate: ['type']
          }}
          styles={{
            icon: 'local_taxi',
            color: 'green',
          }}
          displayProps={valueProps}
        />

        <VehicleLayer
          control={{
            name: I18n.t('supervision.vehicle.status.approaching'),
            group: 'vehicle'
          }}
          ids={ids}
          config={{
            model_name: 'vehicle',
            postFilter: filterApprochingVehicles,
            populate: ['current_job.steps.order.assigned_transport', 'type'],
          }}
          styles={{
            icon: 'local_taxi',
            color: 'orange'
          }}
          displayProps={valueProps}
        />

        <VehicleLayer
          control={{
            name: I18n.t('supervision.vehicle.status.arriving'),
            group: 'vehicle',
          }}
          ids={ids}
          config={{
            model_name: 'vehicle',
            postFilter: filterArrivingVehicles,
            populate: ['current_job.steps.order.assigned_transport', 'type'],
          }}
          styles={{
            icon: 'local_taxi',
            color: 'blue'
          }}
          displayProps={valueProps}
        />

        <VehicleLayer
          control={{
            name: I18n.t('supervision.vehicle.status.unavailbale'),
            group: 'vehicle',
          }}
          ids={ids}
          config={{
            model_name: 'vehicle',
            postFilter: useMemo(() => ['status', 'unavailable'], []),
            populate: ['type']
          }}
          styles={{
            icon: 'local_taxi',
            color: 'text.disabled'
          }}
          displayProps={valueProps}
        />
      </MapFactory.LayersControl >
    </MapFactory.Cluster>
  )
}

export default React.memo(VehicleLayers)
