import React from 'react'
import { hooks } from '@front/volcanion'

import { Box } from '@mui/material'

import UserFrame from '@abra/frames/User'

const UserPage = props => {
  const { user_id } = hooks.useParams()
  return (
    <Box className='safearea' sx={{ display: 'flex', justifyContent: 'center' }}>
      <UserFrame record_id={user_id} />
    </Box>
  )
}

export default React.memo(UserPage)
