import React, { useCallback } from 'react'
import { ModelForm, hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { setting_id } = hooks.useParams()

  const formProps = {
    name: 'setting_form',
    model_name: 'setting',
    record_id: setting_id,
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), [])
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
