import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid, Box, StepLabel, StepContent, Typography } from '@mui/material'
import { TextField, SingleOptionSelectorField } from '@front/squirtle/'

const Row1 = ({ record_id, ...props }) =>
  <Grid container item xs={12} spacing={2}>
    <Grid item xs={1}>
      <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} required />
    </Grid>
    <Grid item xs={1}>
      <SingleOptionSelectorField name='active' label={I18n.t('active')} clearOnEmpty={false} />
    </Grid>
    <Grid item xs={3} hidden={!record_id}>
      <TextField name='operator' label={I18n.t('telephonyprofile.operator')} disabled />
    </Grid>
  </Grid>


const PrimarySection = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('info.general')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 5, boxShadow: 6, borderRadius: 3 }}>
      <Grid container rowSpacing={3}>
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
