import React from 'react'

import { TableCell } from '@mui/material'
import { TextCell } from '@abra/elements/table/cells/basics/'

const Row = ({ row, isSelected, ...props }) => <>
  <TableCell size={'small'}><TextCell value={_.get(row, 'name')} /></TableCell>
</>

export default React.memo(Row)
