import React from 'react'
import { Route, Routes } from '@front/squirtle'

import Taxicounter from './Details'
import TaxicounterList from './List'

const TaxicounterPage = props =>
  <Routes>
    <Route index element={<TaxicounterList />} />
    <Route path='/create' element={<Taxicounter />} />
    <Route path='/:taxi_counter_id' element={<Taxicounter />} />
  </Routes>

export default React.memo(TaxicounterPage)
