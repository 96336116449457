class Callbacks {
  static filterApprochingJobsHandler() {
    return function filterApprochingJobs(job) {
      const current_steps = _.filter(_.get(job, 'steps'), ['index', _.get(job, 'current_index')])
      const approching_steps = _.filter(current_steps, ['order.assigned_transport.status', 'started'])
      return !_.isEmpty(approching_steps)
    }
  }

  static filterArrivingJobsHandler() {
    return function filterArrivingJobs(job) {
      const current_steps = _.filter(_.get(job, 'steps'), ['index', _.get(job, 'current_index')])
      const arriving_steps = _.filter(current_steps, ['order.assigned_transport.status', 'on_board'])
      return !_.isEmpty(arriving_steps)
    }
  }

  static filterInProgressJobsHandler() {
    return function filterInProgressJobs(job) {
      return !!_.get(job, 'dispatchedAt') && (_.get(job, 'status') === 'created' || _.get(job, 'status') === 'standby')
    }
  }

  static filterNotServedJobsHandler() {
    return function filterNotServedJobs(job) {
      const current_steps = _.filter(_.get(job, 'steps'), ['index', _.get(job, 'current_index')])
      const not_served_steps = _.filter(current_steps, (step) => _.get(step, 'order.status') === 'canceled' && _.get(step, 'order.reason') === 'not_served')
      return !_.isEmpty(not_served_steps)
    }
  }

}

export default Callbacks
