import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Box, Grid } from '@mui/material'

import Form from '@abra/frames/Option/List/Form'
import Header from '@abra/frames/Option/List/Header'
import Row from '@abra/frames/Option/List/Row'

import Callbacks from './callbacks'

const UserOption = props => {
  const formToFilter = useCallback(Callbacks.formToFilterHandler(), [])
  return (
    <Box className={'safearea'}>
      <Grid container justifyContent='center' >
        <Grid item xs={11}>
          <SearchFrame
            model_name={'useroption'}
            populate={['categoryoption']}
            createButtonProps={{ label: I18n.t('options.useroption.add') }}
          >
            <SearchForm submitOnMount formToFilter={formToFilter}>
              <FormContent>
                <Form title={I18n.t('options.useroption.search')} type='driver' />
              </FormContent>
            </SearchForm>
            <SearchTable>
              <TableHeader><Header /></TableHeader>
              <TableRow><Row /></TableRow>
            </SearchTable>
          </SearchFrame>
        </Grid>
      </Grid>
    </Box>
  )
}
export default React.memo(UserOption)
