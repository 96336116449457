import _ from 'lodash'
import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
const withContainer = Component => props => {
  const [fleet_ids, cp_option] = hooks.useGlobalFieldValues('job_search', ['fleet', 'cp_option'])
  const [fleets] = hooks.useModel('fleet', fleet_ids)
  const requestSubmit = hooks.useGlobalFormFunction('vehicle_search', 'requestSubmit')

  const load_type = _.uniq(_.compact(_.map(fleets, 'load_type')))

  const options_watchers = [_.join(_.compact(load_type), ',')]
  const getOptionsFilter = useCallback(() => {
    if (!_.isEmpty(load_type))
      return { load_type: _.flatten([load_type, null]) }
    return null
  }, [options_watchers])


  const disable_options = !!cp_option
  const mergedProps = {
    disable_options,
    options_watchers,
    getOptionsFilter,
    requestSubmit
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
