import React, { useState, useCallback } from 'react'
import { hooks, I18n } from '@front/volcanion'

import { ConfirmationDialog, MapFactory } from '@front/squirtle'
import LayerControl from '../../Control/Layers'

import JobCard from './Card'


import Callbacks from './callbacks'

const JobLayer = ({ ids, config, styles, displayProps, ...rest }) => {
  const [jobs] = hooks.useModel('job', ids, config)
  const {
    includeSource,
    includeDestination,
    includeJourney
  } = displayProps

  const getSourceStyles = useCallback((instance, records) => ({
    icon: _.head(records)?.first_step?.order?.commercial_package?.monitoring_icon || 'airline_seat_recline_extra'
  }), [])
  const getPolylineCoordinates = useCallback((record) => [_.get(record, 'first_step.order.source.coordinates'), _.get(record, 'last_step.order.destination.coordinates')], [])

  const onMouseOver = useCallback((event) => {
    const map_listener = event.target.getMapListener()
    const record = _.head(event.target.getParentListener()?.getRecords())
    const all_markers = map_listener.getChildrenListeners('marker', true)
    const all_polylines = map_listener.getChildrenListeners('polyline', true)
    const my_markers = map_listener.getChildrenListenersByModel('job', record?.job_id, 'marker', true)
    const my_polylines = map_listener.getChildrenListenersByModel('job', record?.job_id, 'polyline', true)
    const related_markers = map_listener.getChildrenListenersByModel('vehicle', record?.first_step?.order?.active_transport?.vehicle, 'marker', true)
    const related_polylines = map_listener.getChildrenListenersByModel('vehicle', record?.first_step?.order?.active_transport?.vehicle, 'polyline', true)

    _.map(all_markers, (marker) => marker?.getInstance()?.setOpacity(0.3))
    _.map(my_markers, (marker) => marker?.getInstance()?.setOpacity(1))
    _.map(related_markers, (marker) => marker?.getInstance()?.setOpacity(1))
    _.map(my_markers, (marker) => marker?.getInstance()?.setZIndexOffset(10000))
    _.map(related_markers, (marker) => marker?.getInstance()?.setZIndexOffset(10000))

    _.map(all_polylines, (polyline) => polyline?.removeFromParent())
    _.map(my_polylines, (polyline) => polyline?.addToParent())
    _.map(related_polylines, (polyline) => polyline?.addToParent())
  }, [])
  const onMouseOut = useCallback((event) => {
    const map_listener = event.target.getMapListener()
    const all_markers = map_listener.getChildrenListeners('marker', true)
    const all_polylines = map_listener.getChildrenListeners('polyline', true)
    _.map(all_markers, (marker) => marker?.getInstance()?.setOpacity(1))
    _.map(all_markers, (marker) => marker?.getInstance()?.setZIndexOffset(0))
    _.map(all_polylines, (polyline) => polyline?.addToParent())
  }, [])
  const eventHandlers = {
    mouseover: onMouseOver,
    mouseout: onMouseOut
  }
  return (
    <MapFactory.SuperLayer styles={styles} {...rest}>
      {_.map(jobs, ({ job_id }) => (
        <MapFactory.SuperLayer key={job_id}>
          {
            !!includeSource && (
              <MapFactory.Marker
                animate
                interactive
                config={{
                  model_name: 'job',
                  ids: [job_id],
                  populate: ['first_step.order.source', 'first_step.order.commercial_package', 'first_step.order.active_transport'],
                  coordinates_path: 'first_step.order.source.coordinates'
                }}
                getStyles={getSourceStyles}
                eventHandlers={eventHandlers}
                riseOnHover
              >
                <MapFactory.Popup>
                  <JobCard job_id={job_id} />
                </MapFactory.Popup>
              </MapFactory.Marker>
            )
          }
          {
            !!includeJourney && (
              <MapFactory.Polyline
                animate
                config={{
                  model_name: 'job',
                  ids: [job_id],
                  populate: ['first_step.order.source', 'last_step.order.destination'],
                  coordinates_path: getPolylineCoordinates
                }}
              >
                <MapFactory.Popup>
                  <JobCard job_id={job_id} />
                </MapFactory.Popup>
              </MapFactory.Polyline>
            )
          }
          {
            !!includeDestination && (
              <MapFactory.Marker
                animate
                interactive
                config={{
                  model_name: 'job',
                  ids: [job_id],
                  populate: ['last_step.order.destination', 'first_step.order.active_transport'],
                  coordinates_path: 'last_step.order.destination.coordinates'
                }}
                styles={{ icon: 'golf_course' }}
                eventHandlers={eventHandlers}
                riseOnHover
              >
                <MapFactory.Popup>
                  <JobCard job_id={job_id} />
                </MapFactory.Popup>
              </MapFactory.Marker>
            )
          }
        </MapFactory.SuperLayer>
      ))}
    </MapFactory.SuperLayer>
  )
}

const JobLayers = ({ ids }) => {
  const [dispatchJob] = hooks.useModelFunction('job', 'dispatch')
  const [enableClustering, setEnableClustering] = useState(true)
  const [includeSource, setIncludeSource] = useState(true)
  const [includeDestination, setIncludeDestination] = useState(true)
  const [includeJourney, setIncludeJourney] = useState(true)
  const [includeIntermediateSteps, setIncludeIntermediateSteps] = useState(false)

  const valueProps = {
    enableClustering,
    includeSource,
    includeDestination,
    includeJourney,
    includeIntermediateSteps,
  }

  const options = [{
    icon: 'airline_seat_recline_extra',
    label: I18n.t('supervision.job.options.source'),
    value: includeSource,
    onChange: setIncludeSource,
  }, {
    icon: 'golf_course',
    label: I18n.t('supervision.job.options.destination'),
    value: includeDestination,
    onChange: setIncludeDestination,
  }, {
    icon: 'trending_flat',
    label: I18n.t('supervision.job.options.journey'),
    value: includeJourney,
    onChange: setIncludeJourney,
  }, {
    icon: 'change_circle',
    label: I18n.t('supervision.job.options.steps'),
    value: includeIntermediateSteps,
    onChange: setIncludeIntermediateSteps,
  }, {
    icon: 'donut_large',
    label: I18n.t('supervision.job.options.clustering'),
    value: enableClustering,
    onChange: setEnableClustering,
  }]
  const filterApprochingJobs = useCallback(Callbacks.filterApprochingJobsHandler(), [])
  const filterArrivingJobs = useCallback(Callbacks.filterArrivingJobsHandler(), [])
  const filterInProgressJobs = useCallback(Callbacks.filterInProgressJobsHandler(), [])
  const filterNotServedJobs = useCallback(Callbacks.filterNotServedJobsHandler(), [])
  const onCancelDispatch = useCallback(({ job_id }) => dispatchJob({ job_id }), [dispatchJob])

  return (
    <MapFactory.Cluster disableClustering={!enableClustering} >
      <ConfirmationDialog
        name={'confirm_cancel_dispatch'}
        onConfirm={onCancelDispatch}
      />
      <MapFactory.LayersControl position={'topright'} hasMinimize defaultMinimized>
        <MapFactory.ControlBox
          title={[I18n.t(`display.label`, { count: 1 }), _.toLower(I18n.t(`job.label`, { count: 1 }))].join(' ')}
          defaultOpen
        >
          <LayerControl options={options} />
        </MapFactory.ControlBox>
        <JobLayer
          control={{
            name: I18n.t('supervision.job.status.approaching'),
            group: 'job',
          }}
          ids={ids}
          config={{
            model_name: 'job',
            populate: ['steps.order.assigned_transport'],
            postFilter: filterApprochingJobs
          }}
          styles={{ color: 'orange' }}
          displayProps={valueProps}
        />

        <JobLayer
          control={{
            name: I18n.t('supervision.job.status.arriving'),
            group: 'job'
          }}
          ids={ids}
          config={{
            model_name: 'job',
            populate: ['steps.order.assigned_transport'],
            postFilter: filterArrivingJobs
          }}
          styles={{ color: 'blue' }}
          displayProps={valueProps}
        />

        <JobLayer
          control={{
            name: I18n.t('supervision.job.status.in_progress'),
            group: 'job',
            defaultEnabled: true
          }}
          ids={ids}
          config={{
            model_name: 'job',
            postFilter: filterInProgressJobs
          }}
          styles={{ color: 'purple' }}
          displayProps={valueProps}
        />

        <JobLayer
          control={{
            name: I18n.t('supervision.job.status.not_served'),
            group: 'job',
            defaultEnabled: true
          }}
          ids={ids}
          config={{
            model_name: 'job',
            populate: ['steps.order'],
            postFilter: filterNotServedJobs
          }}
          styles={{ color: 'red' }}
          displayProps={valueProps}
        />
      </MapFactory.LayersControl>
    </MapFactory.Cluster>
  )
}

export default React.memo(JobLayers)
