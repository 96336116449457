import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'
import { TextField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'}> {I18n.t('rateparameter.search')} </Typography>
  </Grid>

const Row1 = props =>
  <Grid item xs={6}>
    <TextField name={'name'} label={I18n.t('name.label', { count: 1 })} />
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12} sm={6}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container rowSpacing={3} justifyContent={'center'}>
          <Title />
          <Row1 />
        </Grid>
      </Box>
    </Grid >
  </Grid >


export default React.memo(SearchForm)
