import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import TextHeader from '@abra/elements/table/headers/Text'

const Header = props => <>
  <TableCell align='center'><TextHeader label={I18n.t('vehicletype.label', { count: 1 })} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('vehicle.plaque')} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('vehicle.brand', { count: 1 })} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('vehicle.model', { count: 1 })} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('vehicle.in_service')} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('contract.license_id')} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('contract.id')} /></TableCell>
  <TableCell align='center'><TextHeader label={I18n.t('group.label', { count: 1 })} /></TableCell>
</>

export default React.memo(Header)
