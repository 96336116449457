import React from 'react'
import { I18n } from '@front/volcanion'

import { Typography, Grid, Box } from '@mui/material'
import { TextField, ModelMenuSelectorField, ModelAutocompleteField, DateField } from '@front/squirtle'


const Row1 = props =>
  <Grid container item xs={12} spacing={1} >
    <Grid item xs>
      <TextField
        label={I18n.t('vehicle.plaque')}
        name='info.plaque'
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        label={I18n.t('vehicle.brand', { count: 1 })}
        name='brand'
        model_name={'vehiclebrand'}
        labelKeys={['name']}
        autocompleteKey={'name'}
        searchMode={'search'}
        config={{ initial_filter: {} }}
        minQueryLength={1}
      />
    </Grid>
    <Grid item xs>
      <ModelAutocompleteField
        label={I18n.t('vehicle.model', { count: 1 })}
        name='model'
        model_name={'vehiclemodel'}
        labelKeys={['name']}
        autocompleteKey={'name'}
        searchMode={'search'}
        config={{ initial_filter: {} }}
        minQueryLength={1}
      />
    </Grid>
    <Grid item xs>
      <DateField
        label={I18n.t('vehicle.in_service')}
        name='info.in_service_date'
        inputFormat={'DD/MM/YYYY'}
      />
    </Grid>
  </Grid>

const Row2 = props =>
  <Grid container item xs={12} spacing={1} >
    <Grid item xs={3}>
      <TextField
        label={I18n.t('contract.license_id')}
        name='parent_license.license_number'
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        label={I18n.t('contract.id')}
        name='parent_license.license_client_id'
      />
    </Grid>
    <Grid item xs={6}>
      <ModelMenuSelectorField
        label={I18n.t('options.label', { count: 2 })}
        name='options'
        model_name='vehicleoption'
        loadOnMount
        searchMode='search'
        labelKeys={['name_translated']}
        multiple
        allowNone={false}
      />
    </Grid>
    <Grid item xs={3}>
      <ModelMenuSelectorField
        label={I18n.t('vehicletype.label', { count: 2 })}
        name='type'
        model_name='vehicletype'
        loadOnMount
        searchMode='search'
        labelKeys={['name_translated']}
        multiple
        allowNone={false}
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Box sx={{ boxShadow: 3, borderRadius: 1, p: 2 }}>
    <Grid container rowSpacing={3}>
      <Grid item xs={9}>
        <Typography variant={'h5'}>{I18n.t('vehicle.search')}</Typography>
      </Grid>
      <Row1 />
      <Row2 />
    </Grid>
  </Box>
export default React.memo(SearchForm)
