class Callbacks {
  static formToFilterHandler() {
    return function formToFilter(values, extra, meta, state) {
      if (_.has(values, 'name'))
        _.merge(values, { name: { contains: values?.name } })

      return {
        generic: true,
        ...values
      }
    }
  }
}

export default Callbacks
