import React, { useCallback } from 'react'
import { ModelForm, hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { user_id } = hooks.useParams()
  const [, { openDialog }] = hooks.useDialogs()

  const formProps = {
    name: 'staff_form',
    model_name: 'user',
    record_id: user_id,
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(user_id), [user_id]),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(openDialog, user_id), [openDialog, user_id]),
    populate: ['auth', 'info'],
    submitChangesOnly: false,
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
