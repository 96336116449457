import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [userModify] = hooks.useModelFunction('user', 'modify')
  const [record_id] = hooks.useFormState('record_id')
  const [user_auth_id] = hooks.useFormState('user_auth_id')

  const onConfirmPassword = useCallback(Callbacks.onConfirmPasswordHandler(userModify, record_id), [userModify, record_id])

  const mergedProps = {
    user_auth_id,
    onConfirmPassword,
  }

  return <Component {...mergedProps} />
}

export default withContainer
