import React from 'react'

const withContainer = Component => props => {
  const mergedProps = {}
  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
