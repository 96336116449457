import React from 'react'
import { Route, Routes } from '@front/squirtle'

import VehicleType from './Details'
import VehicleTypeList from './List'

const VehicleTypePage = props =>
  <Routes>
    <Route index element={<VehicleTypeList />} />
    <Route path='/create' element={<VehicleType />} />
    <Route path='/:vehicletype_id' element={<VehicleType />} />
  </Routes>

export default React.memo(VehicleTypePage)
