import React, { useCallback } from 'react'

import { SingleOptionSelectorComponent, MapHooks } from '@front/squirtle'
import { Grid } from '@mui/material'


const SingleLayerSelector = ({ layer, isMinimized, handleChange }) => {

  const [isLayerEnabled, count, color] = MapHooks.useLayersControlListener(
    useCallback((instance) => [instance?.isLayerEnabled(layer), instance?.getLayerMarkerCount(layer), layer.getStyle('color')], [layer])
  )
  return (
    <SingleOptionSelectorComponent
      value={isLayerEnabled}
      checked={isLayerEnabled}
      onChange={value => handleChange(layer, value)}
      optionLabel={isMinimized ? `(${count || '-'})` : [layer.getControlName(), `(${count || '-'})`].join(' ')}
      getOptionLabelProps={() => ({
        componentsProps: { typography: { sx: { fontSize: 13, color, fontWeight: 600 } } },
        labelPlacement: isMinimized ? 'end' : 'bottom'
      })}
    />
  )
}

const LayerContent = ({ grouped_layer, isMinimized }) => {
  const handleChange = useCallback((layer, isChecked) => !!isChecked ? layer.addToParent() : layer.removeFromParent(), [])

  return (
    <Grid container xs={12} justifyContent={'center'} alignItems={'center'} >
      {_.map(grouped_layer, layer => (
        <Grid key={layer?.getParentListener()?.getId()} item xs={isMinimized ? 12 : 6} justifyContent={'center'} alignItems={'center'}>
          <SingleLayerSelector
            layer={layer}
            handleChange={handleChange}
            isMinimized={isMinimized}
          />
        </Grid >
      ))}
    </Grid>
  )
}

export default React.memo(LayerContent)
