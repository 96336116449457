class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        rateparameter: {
          name: _.get(record, 'name'),
          flat_rate: _.get(record, 'flat_rate'),
          km_rate: _.get(record, 'km_rate'),
          minute_rate: _.get(record, 'minute_rate'),
          min_rate: _.get(record, 'min_rate'),
          max_rate: _.get(record, 'max_rate'),
          'min_rate--checkbox': _.isNumber(_.get(record, 'min_rate')),
          'max_rate--checkbox': _.isNumber(_.get(record, 'max_rate')),
          handling_rate: _.get(record, 'handling_rate'),
          handling_slice: _.get(record, 'handling_slice'),
          immediate_empty_flat_rate: _.get(record, 'immediate_empty_flat_rate'),
          planned_empty_flat_rate: _.get(record, 'planned_empty_flat_rate'),
          empty_km_rate: _.get(record, 'empty_km_rate'),
          ridemodeconfig: {
            validate_base: _.get(record, 'ridemodeconfig.validate_base'),
            prefill_base: _.get(record, 'ridemodeconfig.prefill_base'),
            display_handling_field: _.get(record, 'ridemodeconfig.display_handling_field'),
            display_fee_field: _.get(record, 'ridemodeconfig.display_fee_field'),
            display_prepaid_field: _.get(record, 'ridemodeconfig.display_prepaid_field')
          }
        }
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      const returnMinRate = _.get(values, 'rateparameter.min_rate--checkbox')
      const returnMaxRate = _.get(values, 'rateparameter.max_rate--checkbox')

      const ridemconfigHasChange =
        _.has(values, 'rateparameter.ridemodeconfig.validate_base') ||
        _.has(values, 'rateparameter.ridemodeconfig.prefill_base') ||
        _.has(values, 'rateparameter.ridemodeconfig.display_handling_field') ||
        _.has(values, 'rateparameter.ridemodeconfig.display_fee_field') ||
        _.has(values, 'rateparameter.ridemodeconfig.display_prepaid_field')

      return {
        name: _.get(values, 'rateparameter.name'),
        flat_rate: _.get(values, 'rateparameter.flat_rate'),
        km_rate: _.get(values, 'rateparameter.km_rate'),
        minute_rate: _.get(values, 'rateparameter.minute_rate'),
        min_rate: !!returnMinRate ? _.get(values, 'rateparameter.min_rate', 0) : null,
        max_rate: !!returnMaxRate ? _.get(values, 'rateparameter.max_rate', 0) : null,
        handling_rate: _.get(values, 'rateparameter.handling_rate'),
        handling_slice: _.get(values, 'rateparameter.handling_slice'),
        immediate_empty_flat_rate: _.get(values, 'rateparameter.immediate_empty_flat_rate'),
        planned_empty_flat_rate: _.get(values, 'rateparameter.planned_empty_flat_rate'),
        empty_km_rate: _.get(values, 'rateparameter.empty_km_rate'),
        ridemodeconfig: !!ridemconfigHasChange ? {
          validate_base: _.get(values, 'rateparameter.ridemodeconfig.validate_base'),
          prefill_base: _.get(values, 'rateparameter.ridemodeconfig.prefill_base'),
          display_handling_field: _.get(values, 'rateparameter.ridemodeconfig.display_handling_field'),
          display_fee_field: _.get(values, 'rateparameter.ridemodeconfig.display_fee_field'),
          display_prepaid_field: _.get(values, 'rateparameter.ridemodeconfig.display_prepaid_field')
        } : undefined
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'name')
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {
        rateparameter: {
          flat_rate: 0,
          km_rate: 0,
          minute_rate: 0,
          max_rate: 0,
          handling_rate: 0,
          handling_slice: 0,
          immediate_empty_flat_rate: 0,
          planned_empty_flat_rate: 0,
          empty_km_rate: 0,
          ridemodeconfig: {
            validate_base: false,
            prefill_base: false,
            display_handling_field: false,
            display_fee_field: false,
            display_prepaid_field: false,
          }
        }
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)
    }
  }

  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)
    }
  }
}

export default Callbacks
