import React, { useCallback, useState } from 'react'
import { hooks, I18n } from '@front/volcanion'
import { Loader } from '@front/squirtle'
import { usePhoneCall } from '@abra/hooks/ivr'
import { HookUtils } from '@front/volcanion/utils'
const withContainer = Component => ({ job_id }) => {
  const [job, jobState] = hooks.useModel('job', [job_id], {
    single: true,
    populate: [
      'first_step.order.source',
      'first_step.order.loads',
      'last_step.order.destination',
      'first_step.order.active_transport.driver.info',
      'first_step.order.active_transport.driver.customerinfo'
    ]
  })
  const order_id = job?.first_step?.order?.order_id
  const [, { openDialog }] = hooks.useDialogs()
  const [{ name: driver_name }] = hooks.useCustomerInfo(job?.first_step?.order?.active_transport?.driver?.user_id)
  const current_driver_name = _.compact([job?.first_step?.order?.active_transport?.driver?.info?.user_client_id, driver_name]).join(' - ')
  const hasTransport = !!_.get(job, 'first_step.order.active_transport')
  const [dispatchJob] = hooks.useModelFunction('job', 'dispatch')
  const onCall = usePhoneCall()
  const [driverId, setDriverId] = useState()
  const onClick = useCallback(() => {
    if (!!job_id && !!driverId)
      dispatchJob({ job_id, user_id: driverId, options: { skip_blacklist: true } })
  }, [job_id, driverId])
  const onDispatchCancelClick = useCallback((id) => {
    openDialog('confirm_cancel_dispatch', {
      title: I18n.t("supervision.popup.cancel_dispatch.title"),
      description: I18n.t("supervision.popup.cancel_dispatch.description")
    }, { job_id })
  }, [openDialog])
  const mergedProps = {
    job,
    onCall,
    onClick,
    current_driver_name,
    driverId,
    order_id,
    setDriverId,
    hasTransport,
    onDispatchCancelClick
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([jobState])}>
      <Component {...mergedProps} />
    </Loader>
  )
}

export default withContainer
