class Callbacks {
  static fleetChangeHandler(setFormValues, setVehicleFormValues, load_type, commercial_packages, user_options, vehicle_options) {
    return function fleetChange(value) {
      const load_type_value = !_.isEmpty(load_type) ? load_type : undefined
      const commercial_packages_value = !_.isEmpty(load_type_value) ? _.map(_.filter(commercial_packages, (cp) => _.includes(load_type_value, cp.load_type)), 'commercial_package_id') : _.map(commercial_packages, 'commercial_package_id')
      const user_options_value = !_.isEmpty(load_type_value) ? _.filter(user_options, (u_o) => _.includes(load_type_value, u_o.load_type)) : user_options
      const vehicle_options_value = !_.isEmpty(load_type_value) ? _.filter(vehicle_options, (v_o) => _.includes(load_type_value, v_o.load_type)) : vehicle_options

      setFormValues([
        { field: 'commercial_package', value: commercial_packages_value }
      ])
      setVehicleFormValues([
        { field: 'user_options', value: user_options_value },
        { field: 'vehicle_options', value: vehicle_options_value }
      ])
    }
  }

  static cpChangeHandler(setVehicleFormValues, commercial_packages, enable_option) {
    return function cpChange(value) {
      const user_options = _.uniq(_.compact(_.flatten(_.map(commercial_packages, (cp) => _.map(cp.default_driver_options, 'user_option_id')))))
      const vehicle_options = _.uniq(_.compact(_.flatten(_.map(commercial_packages, (cp) => _.map(cp.default_vehicle_options, 'vehicle_option_id')))))
      if (!!enable_option) {
        setVehicleFormValues([
          { field: 'user_options', value: user_options },
          { field: 'vehicle_options', value: vehicle_options }
        ])
      }
    }
  }

  static switchChangeHandler(setVehicleFormValues, commercial_packages) {
    return function switchChange(value) {
      const user_options = _.uniq(_.compact(_.flatten(_.map(commercial_packages, (cp) => _.map(cp.default_driver_options, 'user_option_id')))))
      const vehicle_options = _.uniq(_.compact(_.flatten(_.map(commercial_packages, (cp) => _.map(cp.default_vehicle_options, 'vehicle_option_id')))))
      if (!!value) {
        setVehicleFormValues([
          { field: 'user_options', value: user_options },
          { field: 'vehicle_options', value: vehicle_options }
        ])
      }
    }
  }
}

export default Callbacks
