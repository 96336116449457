class Callbacks {
  static onConfirmPasswordHandler(userModify, record_id) {
    return async function onConfirmPassword(values, extra, meta, state) {
      const { password } = values || {}
      await userModify({ filter: { user_id: record_id }, updates: { auth: { password }, type: 'staff' } })
    }
  }
}


export default Callbacks
