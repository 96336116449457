import React, { useCallback } from 'react'

import { MapHooks } from '@front/squirtle'
import { Box, Divider, Grid } from '@mui/material'
import LayerGrouped from './Grouped'
import OptionsControl from './Options'

const LayersControl = ({ isMinimized, options, children }) => {
  const layers = MapHooks.useLayersControlListener(useCallback((instance) => instance?.getLayers(), []))
  const layers_with_groups = _.filter(layers, layer => !!layer.getControlGroup())
  const grouped_layers = _.groupBy(layers_with_groups, layer => layer?.getControlGroup())
  return (
    <Box sx={{ width: isMinimized ? 75 : 350 }}>
      <Grid container justifyContent={'center'}>
        {_.map(grouped_layers, (grouped_layer, group_name) =>
          <LayerGrouped isMinimized={isMinimized} key={group_name} grouped_layer={grouped_layer} group_name={group_name} />
        )}
      </Grid>
      <Grid item xs={12} >
        <Divider variant='middle' sx={{ mt: 2, mb: 2 }} />
      </Grid>
      <OptionsControl isMinimized={isMinimized} options={options} />
      {children}
    </Box>
  )
}

export default React.memo(LayersControl)

