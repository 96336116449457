
import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [enable_min_rate, enable_max_rate] = hooks.useFieldValues(['rateparameter.min_rate--checkbox', 'rateparameter.max_rate--checkbox'])
  const mergedProps = {
    enable_min_rate,
    enable_max_rate,
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
